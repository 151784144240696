<template>
  <div>
    <h2
      class="u-margin-bottom-xsmall production-options__item-title"
      :class="{
        'production-options__item--inactive': disabled,
      }"
    >
      Is Overseas Production Acceptable?
      <template v-if="!disabled">
        <span v-if="!overseasOption" style="color: red;font-style: italic;"
          >* Required</span
        >
        <i v-else class="fa fa-check" style="color: #C7E615;" />
      </template>
    </h2>
    <p
      class="paragraph paragraph--large paragraph--grey u-margin-bottom-xsmall"
      :class="{
        'production-options__item--inactive': disabled,
      }"
    >
      For CNC machining, urethane casting and injection molding projects, we
      offer the option to manufacture within the United States or abroad.
    </p>
    <div class="u-flex-left u-flex-left--space u-margin-top-xsmall">
      <InputRadioGroup
        :selected="overseasOption"
        :items="options"
        :disabled="disabled"
        :width="width"
        @select="onChangeOverseasOption"
        class="u-margin-bottom-small"
      >
      </InputRadioGroup>
      <div>
        <div
          v-if="errorMessage"
          class="u-margin-bottom-xsmall u-margin-top-xsmall"
        >
          <p class="paragraph paragraph--red">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputRadioGroup from '../inputs/InputRadioGroup.vue'

export default {
  name: 'OverseasProductionAcceptable',
  components: {
    InputRadioGroup,
  },
  props: {
    overseasOption: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '50rem',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirm: false,
      errorMessage: '',
    }
  },
  computed: {
    options() {
      return [
        {
          title: `<p class="paragraph paragraph--grey paragraph--large ${
            this.disabled ? 'production-options__item--inactive' : ''
          }"><strong>Yes</strong>, overseas production is acceptable.</p>`,
          value: 'yes',
        },
        {
          title: `<p class="paragraph paragraph--grey paragraph--large ${
            this.disabled ? 'production-options__item--inactive' : ''
          }"><strong>No</strong>, keep this project within the United States.</p>`,
          value: 'no',
        },
        {
          title: `<p class="paragraph paragraph--grey paragraph--large ${
            this.disabled ? 'production-options__item--inactive' : ''
          }">No preference.</p>`,
          value: 'no_preference',
        },
      ]
    },
  },
  methods: {
    onChangeOverseasOption(value) {
      this.confirm = false
      this.$emit('change', value)
    },
  },
}
</script>
