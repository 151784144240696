<template>
  <div
    class="file-uploader-placeholder"
    :class="{ 'file-uploader-placeholder__disabled': !technologySelected }"
    @click="$emit('browse')"
  >
    <i class="fas fa-cloud-upload-alt file-uploader-placeholder__icon" />
    <div class="u-margin-bottom-small">
      <p class="paragraph">
        Drop 3D file here or
        <span class="u-underline u-pointer">browse</span>
      </p>
    </div>
    <div v-if="rejectedText" class="file-uploader-placeholder__rejected">
      <p class="paragraph paragraph--red">{{ rejectedText }}</p>
    </div>
    <div class="u-margin-bottom-xsmall">
      <p class="paragraph paragraph--grey">Max. file size {{ size }}</p>
    </div>
    <p class="paragraph paragraph--grey" v-if="technologySelected">
      Accepted formats:
      <strong class="u-uppercase">{{ acceptedFormats.join(', ') }}</strong>
    </p>
    <p v-else class="paragraph paragraph--grey">
      <strong>
        Please select process for a list of recommended file types.
      </strong>
    </p>
    <p class="paragraph paragraph--linkBlock" v-if="uploadMoreFiles">
      <span class="parts-table__link">
        Having Issues?
      </span>
      <a
        class="parts-table__link--manual"
        href="https://fathommfg.com/manual-quote-sq-backup"
        target="_blank"
      >
        Request via our Manual Quote
      </a>
    </p>
  </div>
</template>

<script>
import filesize from 'filesize'

export default {
  name: 'FileUploaderPlaceholder',
  props: {
    maxFileSize: {
      type: Number,
      default: 0,
    },
    rejectedText: {
      type: String,
      default: '',
    },
    acceptedFormats: {
      type: Array,
      default: () => [],
    },
    technologySelected: {
      type: Boolean,
      default: true,
    },
    uploadMoreFiles: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size() {
      return filesize(this.maxFileSize)
    },
  },
}
</script>
