<template>
  <div
    class="file-uploader"
    :class="{
      'file-uploader--highlite': this.highlited,
      'file-uploader--full-height': this.fullHeight,
      'file-uploader__disabled': this.disabled,
    }"
    @dragleave.prevent="onDragLeave"
    @dragenter.prevent="onDragEnter"
    @dragover.prevent="onDragOver"
    @drop.prevent="onFilesDrop"
  >
    <input
      :disabled="disabled"
      type="file"
      ref="file"
      multiple
      class="file-uploader__elem"
      @change="onUploadFiles"
    />
    <div v-if="files.length" class="file-uploader__wrapper">
      <div class="file-uploader__status-bar">
        <p class="paragraph">
          {{ statusText }}
        </p>
        <div class="file-uploader__rejected">
          <p v-if="rejectedText" class="paragraph paragraph--red">
            {{ rejectedText }}
          </p>
        </div>
      </div>
      <div class="file-uploader__list">
        <FileUploaderItem
          v-for="file in files"
          :key="file.id"
          :file="file"
          :session-id="sessionId"
          :upload-again="uploadAgain"
          :technology-id="technologyId"
          @remove="$emit('fileRemove', $event)"
          @statusChanged="$emit('fileStatusChanged', $event)"
          @unitsChanged="$emit('fileUnitsChanged', $event)"
        />
      </div>
      <div class="file-uploader__action">
        <ButtonUpload @click="selectFiles" />
        <p class="paragraph">or continue below if done uploading</p>
      </div>
    </div>
    <FileUploaderPlaceholder
      v-else
      :max-file-size="maxFileSize"
      :rejected-text="rejectedText"
      :accepted-formats="acceptedFormats"
      :technology-selected="technologySelected && !disabled"
      :upload-More-Files="uploadMoreFiles"
      @browse="selectFiles"
    />
  </div>
</template>

<script>
import { v4 } from 'uuid'
import pluralize from 'pluralize'
import { FileStatuses } from '@/core/utils/constants'

import ButtonUpload from '@/core/components/buttons/ButtonUpload'

import FileUploaderPlaceholder from './FileUploaderPlaceholder'
import FileUploaderItem from './FileUploaderItem'
import { isASCII } from '@/core/utils/common'

export default {
  name: 'FileUploader',
  components: {
    ButtonUpload,
    FileUploaderPlaceholder,
    FileUploaderItem,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    technologySelected: {
      type: Boolean,
      default: true,
    },
    uploadMoreFiles: {
      type: Boolean,
      default: false,
    },
    acceptedFormats: {
      type: Array,
      default: () => ['stp', 'step', 'iges', 'sldprt', '3dm', 'sat', 'x_t'],
    },
    sessionId: {
      type: String,
      default: '',
    },
    uploadAgain: {
      type: Boolean,
      default: false,
    },
    technologyId: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxFileSize: 1024 * 1024 * 100, // 100 MB
      rejectedText: '',
      highlited: false,
      fullHeight: true,
    }
  },
  computed: {
    uploadedFilesCount() {
      return this.files.filter(file => file.status === FileStatuses.UPLOADED)
        .length
    },
    statusText() {
      return `${this.uploadedFilesCount} of ${pluralize(
        'file',
        this.files.length,
        true
      )}  uploaded`
    },
  },
  methods: {
    uploadFiles(rawFiles) {
      let files = Array.from(rawFiles).map(file => this.makeFile(file))

      // filter files with invalid size and formats
      files = files.filter(file => file.original.size <= this.maxFileSize)

      if (files.length !== rawFiles.length) {
        this.rejectedText = 'Some of your files exceeded 100 MB'
      }

      const filesLength = files.length

      const nonAcceptedExtFiles = files.filter(file => {
        const ext = file.original.name
          .toLowerCase()
          .split('.')
          .pop()
        return !this.acceptedFormats.includes(ext)
      })

      const nonAcceptedAsciiFiles = files.filter(file => {
        return !isASCII(file.original.name)
      })

      const acceptedFormatsFiles = files.filter(file =>
        this.acceptedFormats.find(
          format =>
            file.original.name.toLowerCase().indexOf(`.${format}`) !== -1
        )
      )

      if (nonAcceptedExtFiles.length || nonAcceptedAsciiFiles.length) {
        this.$emit('reject', true)
        this.$vfm.show('non-accepted-files', {
          acceptedFormats: this.acceptedFormats,
          nonAcceptedExtFiles,
          nonAcceptedAsciiFiles,
        })
      }

      if (
        acceptedFormatsFiles.length !== filesLength ||
        nonAcceptedAsciiFiles.length
      ) {
        this.$emit('reject', true)
      }

      setTimeout(() => {
        this.rejectedText = ''
        this.$emit('reject', false)
      }, 5000)

      this.$emit('uploadRequest', files)
    },
    onFilesDrop(event) {
      if (!this.disabled) {
        this.uploadFiles(event.dataTransfer.files)

        this.highlited = false
      }
    },
    selectFiles() {
      this.$refs.file.click()
    },
    makeFile(original) {
      return {
        status: FileStatuses.UPLOADING,
        id: v4(),
        original,
      }
    },
    onUploadFiles(event) {
      this.uploadFiles(event.target.files)
    },
    onDragLeave() {
      this.highlited = false
    },
    onDragEnter() {
      this.highlited = true
    },
    onDragOver() {
      this.highlited = true
    },
  },
}
</script>
