<template>
  <div>
    <h2
      class="u-margin-bottom-xsmall production-options__item-title"
      :class="{
        'production-options__item--inactive': disabled,
      }"
    >
      A Quick Question
      <template v-if="technologySelected && !itarOption">
        <span style="color: red;font-style: italic;">* Required</span>
      </template>
      <template v-else>
        <i
          v-if="itarOption === 'yes'"
          class="fa fa-times-circle-o"
          style="color: red;"
        />
        <i
          v-if="itarOption === 'no'"
          class="fa fa-check"
          style="color: #C7E615;"
        />
      </template>
    </h2>
    <p
      class="paragraph paragraph--large paragraph--grey u-margin-bottom-xsmall"
      :class="{
        'production-options__item--inactive': disabled,
      }"
    >
      Before you upload your files, is your project subject to export control
      regulations including, but not limited to, the International Traffic in
      Arms Regulations (ITAR), 22 C.F.R. 120-130? <br />(Not sure what that
      means?

      <a
        class="link--gray"
        target="_blank"
        href="https://fathommfg.com/itar-manufacturing"
      >
        <span
          class="paragraph--strong"
          :class="{
            'production-options__item--inactive': disabled,
          }"
          >Click here to learn more.</span
        > </a
      >)
    </p>
    <p
      class="paragraph--large paragraph--grey paragraph--strong u-margin-bottom-small"
      :class="{
        'production-options__item--inactive': disabled,
      }"
    >
      To submit an ITAR or regulated project,
      <a
        class="link--gray"
        target="_blank"
        href="https://fathommfg.com/itar-request"
      >
        <span
          class="paragraph--strong"
          :class="{
            'production-options__item--inactive': disabled,
          }"
          >click here.</span
        >
      </a>
    </p>
    <div class="u-flex-left u-flex-left--space u-margin-top-xsmall">
      <InputRadioGroup
        :selected="itarOption"
        :items="options"
        :disabled="disabled"
        :width="width"
        @select="onChangeItarOption"
        class="u-margin-bottom-small"
      >
        I can self-attest that files I am uploading are not ITAR
      </InputRadioGroup>
      <div>
        <div
          v-if="errorMessage"
          class="u-margin-bottom-xsmall u-margin-top-xsmall"
        >
          <p class="paragraph paragraph--red">{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <div v-if="showItarMessage" class="u-margin-bottom-small">
      <span
        class="paragraph--large paragraph--grey"
        :class="{
          'production-options__item--inactive': disabled,
        }"
      >
        <span class="paragraph--red paragraph--strong"
          >*Do not submit ITAR or regulated projects here.
          <a
            class="link--red link--normal"
            :class="{
              'production-options__item--inactive': disabled,
            }"
            target="_blank"
            href="https://fathommfg.com/itar-request"
          >
            To submit an ITAR or regulated project, click here.
          </a>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import InputRadioGroup from '../inputs/InputRadioGroup.vue'

export default {
  name: 'ItarSelect',
  components: {
    InputRadioGroup,
  },
  props: {
    itarOption: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '50rem',
    },
    technologySelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirm: false,
      errorMessage: '',
    }
  },
  computed: {
    showItarMessage() {
      return this.itarOption === 'yes'
    },
    disabled() {
      return !this.technologySelected
    },
    options() {
      return [
        {
          title: `<p class="paragraph paragraph--grey paragraph--large ${
            this.disabled ? 'production-options__item--inactive' : ''
          }"><strong>No</strong>, this project <span class="u-underline">does not</span> contain data subject to Export Control regulations</p>`,
          value: 'no',
        },
        {
          title: `<p class="paragraph paragraph--grey paragraph--large ${
            this.disabled ? 'production-options__item--inactive' : ''
          }"><strong>Yes</strong>, this project contains data subject to Export Control regulations</p>`,
          value: 'yes',
        },
      ]
    },
  },
  methods: {
    onChangeItarOption(value) {
      this.confirm = false
      this.$emit('change', value)
    },
  },
}
</script>
