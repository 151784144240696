<template>
  <AppModal
    name="success"
    :closable="closable"
    :loading="loading"
    @beforeOpen="onBeforeOpen"
    @closed="onClosed"
    @close="onCloseHandler"
  >
    <div class="success-modal">
      <div class="success-modal__icon" />
      <div class="success-modal__content">
        <template v-if="type === 'quote-requested'">
          <h3 class="success-modal__title">Quote Request Received</h3>
          <p class="success-modal__text">
            Your quote request has been successfully submitted for review.
            <br />
            A quote confirmation has been emailed to you.
          </p>
        </template>
        <template v-if="type === 'quote-review-requested'">
          <h3 class="success-modal__title">
            Success! Review Request Was Submitted
          </h3>
          <p class="success-modal__text">
            Our engineering team will quickly review your parts.
            <br />
            Expect us to get back to you shortly.
          </p>
        </template>
        <template v-if="type === 'order-submitted'">
          <div class="success-modal__heading">
            <h3 class="success-modal__subtitle">
              Thank you!
            </h3>
            <p class="success-modal__text success-modal__text--black">
              Let's transform the future of manufacturing together!
              <br />
              We truly appreciate your order.
            </p>
          </div>
          <p class="success-modal__text">
            Your <strong> Order# {{ purchaseOrderNumber }} </strong> has been
            submitted successfully.
            <br />
            An order confirmation has been emailed to you. An invoice with the
            <br />
            final charges will be emailed to you when the order ships.
          </p>
        </template>

        <template v-if="showForm">
          <form @submit.prevent="submitForm" class="success-form">
            <h3 class="success-form__title">
              To help expedite your quote, please answer the questions below.
            </h3>
            <div class="success-form__row">
              <InputSelect
                label="Project State:"
                :inline="true"
                :options="[
                  {
                    title: 'Production',
                    value: 'Production',
                  },
                  {
                    title: 'Late stage prototype',
                    value: 'Late stage prototype',
                  },
                  {
                    title: 'Early stage prototype',
                    value: 'Early stage prototype',
                  },
                ]"
                v-model="form.projectStage"
              />
            </div>
            <div class="success-form__row">
              <InputSelect
                label="Decision Timeline:"
                :inline="true"
                :options="[
                  {
                    title: 'Within a few days',
                    value: 'Within a few days',
                  },
                  {
                    title: 'Within a week',
                    value: 'Within a week',
                  },
                  {
                    title: 'No Firm Timeline',
                    value: 'No Firm Timeline',
                  },
                  {
                    title: 'Just getting a quote',
                    value: 'Just getting a quote',
                  },
                ]"
                v-model="form.decisionTimeline"
              />
            </div>
            <div class="success-form__row">
              <InputSelect
                label="Do you have a budget?"
                :inline="true"
                :options="[
                  {
                    title: 'Yes',
                    value: 'Yes',
                  },
                  {
                    title: 'I have a rough budget',
                    value: 'I have a rough budget',
                  },
                  {
                    title: 'I’m getting quotes to make a budget',
                    value: 'I’m getting quotes to make a budget',
                  },
                ]"
                v-model="form.budget"
              />
            </div>
            <div class="success-form__row">
              <InputSelect
                label="When Do You Need Parts in Hand?"
                :inline="true"
                :options="[
                  {
                    title: 'ASAP',
                    value: 'ASAP',
                  },
                  {
                    title: 'Within a week',
                    value: 'Within a week',
                  },
                  {
                    title: 'Within a month',
                    value: 'Within a month',
                  },
                  {
                    title: 'No Hard deadline',
                    value: 'No Hard deadline',
                  },
                  {
                    title: 'Specific date',
                    value: 'Specific date',
                  },
                ]"
                v-model="form.handByParts"
              />
            </div>
            <div
              class="success-form__row"
              v-if="form.handByParts === 'Specific date'"
            >
              <div class="success-form__datepicker">
                <label
                  class="input-select__label success-form__datepicker-label"
                >
                  Specify Date
                </label>
                <div class="success-form__datepicker-input">
                  <Datepicker v-model="form.handByPartsSpecificDate" />
                </div>
              </div>
            </div>
            <div class="success-form__actions">
              <ButtonPrimary>
                Submit
              </ButtonPrimary>
            </div>
          </form>
        </template>
      </div>
      <div
        v-if="dashboardRedirect && !quoteReviewRequested"
        class="success-modal__footer"
      >
        Redirecting you to your dashboard in
        <strong>{{ seconds }}</strong> seconds. If not redirected,
        <a href="#" @click.prevent="action">click here</a>
      </div>
    </div>
  </AppModal>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
import { getErrorMessage } from '@/core/helpers/error'
import { sendQuotesSurvey } from '@/api/commonApi'
import { PHONE_MASK } from '@/core/utils/masks'

import AppModal from '@/core/components/modals/AppModal'
import InputSelect from '@/core/components/inputs/InputSelect'
import ButtonPrimary from '@/core/components/buttons/ButtonPrimary'

export default {
  name: 'SuccessModal',
  components: {
    AppModal,
    InputSelect,
    ButtonPrimary,
    Datepicker,
  },
  data() {
    return {
      dashboardRedirect: false,
      type: '',
      clicked: false,
      seconds: 10,
      timer: null,
      closedTimer: null,
      onClose: null,
      purchaseOrderNumber: '',
      isInjectionMolding: false,
      isPrintingTechnology: false,
      isSheetMetal: false,
      quoteId: '',
      errorMessage: '',
      loading: false,
      closable: false,
      phoneMask: PHONE_MASK,
      form: {
        projectStage: '',
        decisionTimeline: '',
        budget: '',
        handByParts: '',
        handByPartsSpecificDate: '',
      },
    }
  },
  computed: {
    quoteReviewRequested() {
      return this.type === 'quote-review-requested'
    },
    quoteRequested() {
      return this.type === 'quote-requested'
    },
    showForm() {
      return this.quoteReviewRequested || this.quoteRequested
    },
    substitutionLabel() {
      return `Are Material${
        this.isPrintingTechnology ? '/ Process' : ''
      } Substitutions acceptable?`
    },
  },
  methods: {
    onBeforeOpen(e) {
      this.type = e.ref.params.value.type
      this.dashboardRedirect = e.ref.params.value.dashboardRedirect
      this.redirectTime = e.ref.params.value.redirectTime || 10
      this.onClose = e.ref.params.value.onClose
      this.purchaseOrderNumber = e.ref.params.value.purchaseOrderNumber || ''
      this.quoteId = e.ref.params.value.quoteId || ''
      this.clicked = false
      this.closable = e.ref.params.value.closable || false
      this.isInjectionMolding = e.ref.params.value.isInjectionMolding || false
      this.isPrintingTechnology =
        e.ref.params.value.isPrintingTechnology || false
      this.isSheetMetal = e.ref.params.value.isSheetMetal || false

      if (
        !this.quoteReviewRequested &&
        !this.quoteRequested &&
        this.dashboardRedirect &&
        !this.timer
      ) {
        this.setupTimers()
      }
    },
    setupTimers() {
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1
        }
        if (!this.seconds) {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
      this.closedTimer = setTimeout(() => {
        if (!this.clicked) {
          this.action()
        }
      }, this.seconds * 1000)
    },
    onClosed() {
      if (this.timer) {
        clearInterval(this.timer)
      }

      if (this.closedTimer) {
        clearInterval(this.closedTimer)
      }

      this.errorMessage = ''
      this.dashboardRedirect = false
      this.type = ''
      this.seconds = 10
      this.clicked = true
      this.timer = null
      this.closedTimer = null
      this.purchaseOrderNumber = ''
      this.quoteId = ''
      this.loading = false
      this.closable = false
      this.isInjectionMolding = false
      this.isPrintingTechnology = false
      this.isSheetMetal = false
      this.form = {
        projectStage: '',
        decisionTimeline: '',
        budget: '',
        handByParts: '',
        handByPartsSpecificDate: '',
      }
    },
    async action() {
      this.clicked = true

      if (this.onClose && typeof this.onClose === 'function') {
        await this.onClose()
      }

      this.$vfm.hide('success')
    },
    async submitForm() {
      try {
        this.loading = true

        this.form.handByPartsSpecificDate = this.form.handByPartsSpecificDate
          ? moment(this.form.handByPartsSpecificDate).format('YYYY-MM-DD')
          : null

        const payload = {
          ...this.form,
        }

        await sendQuotesSurvey(this.quoteId, payload)

        await this.action()
      } catch (err) {
        this.errorMessage = getErrorMessage(err)
      } finally {
        this.loading = false
      }
    },
    onCloseHandler() {
      if (this.quoteReviewRequested) {
        this.action()
      } else {
        this.$vfm.hide('success')
      }
    },
  },
}
</script>
