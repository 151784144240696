<template>
  <div
    class="app-step-section"
    :class="{
      'app-step-section--checked': checked,
      'app-step-section--disabled': disabled,
    }"
  >
    <div class="app-step-section__heading">
      <span v-if="position" class="app-step-section__position">
        {{ position }}
      </span>
      <h3
        class="app-step-section__title"
        :class="{
          'app-step-section__title--inactive': titleInactive,
        }"
        v-html="title"
      />
      <span v-if="checked" class="app-step-section__check" />
      <span v-if="required" class="app-step-section--required">*Required</span>
      <p
        v-if="titleAddon"
        class="app-step-section__addon"
        v-html="titleAddon"
      />
    </div>
    <div class="app-step-section__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppStepSection',
  props: {
    title: {
      type: String,
      required: true,
    },
    titleAddon: {
      type: String,
      required: false,
    },
    titleInactive: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
      required: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
